<template>
  <footer>
    Copyright &copy; 2012 &mdash; {{ state.year }} <br/> Monitored Communications, LLC
    <span v-if="featureFlags?.webFeatureFlagTest" class="feature-flag-test">
      <br/>{{ state.appVersion }}
    </span>
  </footer>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import { DateTime } from 'luxon'
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'

const commonStore = useCommonStore()
const { featureFlags } = storeToRefs(commonStore)

const state = reactive<{
  year: string
  appVersion: string
}>({
  year: DateTime.now().toFormat('yyyy'),
  appVersion: import.meta.env.VITE_APP_VERSION
})
</script>

<style lang="scss" scoped>
footer {
  background: var(--surface-1);
  // display: grid;
  // place-content: center;
  // height: 1.5625rem;
  font-size: var(--font-size-00);
  color: var(--gray-6);
  // border-top: 1px solid var(--surface-3);
  text-align: center;
  position: relative;
  line-height: var(--lineheight-3);
  margin-bottom: 1rem;

  @media (min-width: 414px) {
    // height: 3.125rem;
    font-size: var(--font-size-00);
  }
}

.feature-flag-test {
  // position: absolute;
  // inset-block: 0;
  // inset-inline-end: 0;
  text-align: center;
}
</style>
