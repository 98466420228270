<template>
  <div class="relative has-dropdown tab-edit-delete" ref="menu">
    <button class="btn circle" @click="toggleMenu">
      <span v-if="!allCardsTab">
        <span v-if="horizontal">
          <font-awesome-icon
            icon="fa-light fa-ellipsis"
            :class="props.iconClass"
          />
        </span>
        <span v-else class="flex align-middle">
          <font-awesome-icon
            icon="fa-regular fa-ellipsis-vertical"
            :class="props.iconClass"
          />
          <span v-if="buttonText" class="ml-00">
            {{ buttonText }}
          </span>
        </span>
      </span>
      <span class="sr-only">edit tab</span>
    </button>
    <menu class="menu-dropdown" v-if="state.showMenu" :class="props.alignMenu">
      <slot></slot>
    </menu>
  </div>
</template>
<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import { reactive, toRef, ref } from 'vue'

const props = defineProps({
  horizontal: {
    type: Boolean,
    default: false
  },
  allCardsTab: {
    type: Boolean,
    default: false
  },
  alignMenu: {
    type: String,
    default: 'right',
    validator(value: string) {
      // The value must match one of these strings
      return ['left', 'right'].includes(value)
    }
  },
  buttonText: {
    type: String
  },
  iconClass: {
    type: String,
    default: 'icon-size-3'
  }
})

const horizontal = toRef(props, 'horizontal')
const allCardsTab = toRef(props, 'allCardsTab')

const state = reactive({
  showMenu: false
})

const menu = ref()

function toggleMenu() {
  state.showMenu = !state.showMenu
}

onClickOutside(menu, () => {
  if (state.showMenu) state.showMenu = false
})
</script>
<style lang="scss" scoped>
.has-dropdown {
  position: relative;
  display: inline-flex;
}

.menu-dropdown {
  padding: 0;
  position: absolute;
  z-index: 100;
  top: calc(100% + 0.5rem);
  inset-block-start: calc(100% + 0.5rem);
  // inset-inline-start: 0;
  min-width: 9.625rem;
  display: inline-flex;
  flex-direction: column;
  align-self: flex-start;
  font-size: var(--font-size-00);

  padding-top: var(--size-00);
  padding-bottom: var(--size-00);
  padding-block: var(--size-00) var(--size-00);

  background-color: var(--surface-1);
  box-shadow: var(--dropdown-shadow);
  border-radius: var(--radius-3);

  @media (min-width: 1100px) {
    // inset-block-start: calc(100% + 1rem);
    // inset-inline-start: 0;
  }

  header {
    padding-bottom: var(--size-00);
    border-bottom: 1px solid var(--border-color);
  }

  .btn.tertiary {
    --padding: var(--size-00) var(--size-0);
    --justify-content: flex-start;
    --radius: 0;
    --font-size: var(--font-size-0);
    --background: var(--surface-1);

    &:hover {
      background: var(--surface-2);
    }

    svg {
      width: 1.25em;
      height: 1.25em;
    }
  }
  &.right {
    right: 0px;
  }

  &.left {
    left: 0px;
  }
}
</style>
