﻿<template>
  <div>
    <div class="stack size-000">
      <span class="font-size-1 font-weight-2 mt-1">
        {{ t('records.partialRecords.modalBody') }}
      </span>
    </div>
    <div class="mt-2">
      <form id="emailReceiptForm" @submit="onSubmit">
        <div style="margin: 2rem">
          <text-input
            v-model="state.email"
            name="email"
            type="email"
            :label="t('records.partialRecords.emailLabel')"
            label-class="font-medium font-size-1"
            placeholder="Email"
            success-message=""
            aria-describedby="SubjectTip"
            :required="true"
            :error-message="t('records.email.recipient.error')"
            :handleChange="setEmailValid()"
          />
        </div>
      </form>
    </div>
    <div class="flex align-center mt-2">
      <div class="stack gap-2">
        <button
          class="btn primary w-100 font-medium"
          style="margin-top: 0"
          @click="emailPartialRecord()"
          :disabled="
            state.submitting || showNewReplyError || !meta.valid || !meta.dirty
          "
          :class="{ disabled: state.submitting }"
        >
          <!-- <loading
            :loading="
              plan.itemID == state.selectedPlanItemId &&
              state.purchaseMinutesSubmitting
            "
          /> -->
          <strong>{{ t('messages.form.sendButton') }}</strong>
        </button>
        <div v-if="showNewReplyError" class="error">
          {{ t('records.partialRecords.newReplyError') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRecordsStore } from '@/stores/RecordsStore'
import { useMessagesStore } from '@/stores/MessagesStore'
import { storeToRefs } from 'pinia'
import TextInput from '@/components/library/TextInput.vue'
import { useForm } from 'vee-validate'
import type { ISingleConversationRecordParam } from '@/models/interfaces'
const { t } = useI18n({ useScope: 'global' })
import toastService from '@/services/toastService'

const recordsStore = useRecordsStore()

const { isEmailValid } = storeToRefs(recordsStore)

const { getSingleConversationRecord } = recordsStore

const messagesStore = useMessagesStore()

const { doesThreadHaveUpdate } = messagesStore

const props = defineProps({
  threadID: {
    type: Number,
    required: true
  },
  title: {
    type: String,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'success'): void
}>()

const state = reactive({
  email: '',
  submitting: false
})

const { handleSubmit, meta } = useForm({
  initialValues: {
    email: state.email
  }
})

const showNewReplyError = computed(() => {
  // show if Feature flag is turned on and not a free user
  return doesThreadHaveUpdate(props.threadID)
})

async function setEmailValid() {
  isEmailValid.value = meta.value.dirty && meta.value.valid
}

const onSubmit = handleSubmit(() => {
  emailPartialRecord()
})

async function emailPartialRecord() {
  //setEmailForReceipt('')
  state.submitting = true
  const _params: ISingleConversationRecordParam = {
    threadID: props.threadID,
    toEmail: state.email
  }
  const result = await getSingleConversationRecord(_params)
  if (result) {
    toastService.infoToast(
      t('records.partialRecords.emailSentToast', { email: state.email })
    )
    emit('success')
  }
}
</script>

<style lang="scss" scoped>
.btn-add-recipient {
  border: 1px solid var(--dark-green);
  width: 2.5rem;
  height: 2.5rem;
  flex: none;
  top: 0.75rem;
  align-self: flex-start;
  inset-block-start: 2rem;
}

.disabled-add-button {
  border: solid 1px var(--gray-2) !important;
  background: var(--gray-2) !important;
  color: var(--gray-4) !important;
  cursor: not-allowed;
}

.font-medium {
  font-variation-settings: 'wght' 600;
}

:deep(strong) {
  font-variation-settings: 'wght' 600;
}
</style>
