<template>
  <div
    class="app-layout"
    ref="el"
    :class="{ 'viewport-height': layoutOptions.appLayoutHeightOfViewport }"
  >
    <TPHeader />
    <TPMobileHeader v-if="isMobileWidth" />
    <TPMainNav />
    <div v-if="!isOnline" class="tp-offline-container">
      You are currently offline.
    </div>
    <div v-if="loading" class="loading">
      <lottie-player
        :src="loadingIconBackupUrl"
        :backupsrc="loadingIconBackupUrl"
        autoplay
        loop
        width="300px"
        height="300px"
      ></lottie-player>
    </div>
    <router-view v-else />

    <!-- <div>
      <TPFooter />
    </div> -->
    <AdblockCheckComponent></AdblockCheckComponent>
    <video-call></video-call>
    <video-call-error v-if="showVideoCallError"></video-call-error>
    <audio-call v-if="showAudioCall"></audio-call>
  </div>
</template>
<script lang="ts" setup>
import {
  onMounted,
  onUnmounted,
  ref,
  watch,
  inject,
  onBeforeMount,
  computed,
  reactive,
  nextTick
} from 'vue'
import { useI18n } from 'vue-i18n'
import TPHeader from '@/layouts/TPHeader.vue'
import TPMainNav from '@/layouts/TPMainNav.vue'
// import TPFooter from '@/layouts/TPFooter.vue'
import TPMobileHeader from './TPMobileHeader.vue'
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import rg4js, { type RaygunV2UserDetails } from 'raygun4js'
import { useRouter } from 'vue-router'
import { useMessagesStore } from '@/stores/MessagesStore'
import { useCalendarStore } from '@/stores/CalendarStore'
import { useInfoLibraryStore } from '@/stores/InfoLibraryStore'
import LottiePlayer from '@/components/LottiePlayerComponent.vue'

import AdblockCheckComponent from '@/components/AdblockCheckComponent.vue'
import {
  useColorMode,
  useDebounceFn,
  useEventBus,
  useResizeObserver
} from '@vueuse/core'
import { useMoneyTransferStore } from '@/stores/moneyTransferStore'
import type {
  ITrackingIdentifyWithIdFunction,
  IRealTimeNotificationV2,
  IRealTimeNotificationObject,
  IVideoCallNotification,
  ITosResult
} from '@/models/interfaces'
import { realtimeDatabaseService } from '@/services/realtimeDBService'
import { DateTime } from 'luxon'
import { useModals } from '@/composables/useModal/useModal'
import incomingVideoCall from '@/pages/calling/components/incomingVideoCall.vue'
import { useCallingStore } from '@/stores/CallingStore'
import { useVaultStore } from '@/stores/VaultStore'
import { useRecordsStore } from '@/stores/RecordsStore'

import videoCall from '@/pages/calling/VideoCall.vue'
import videoCallError from '@/pages/calling/components/VideoCallError.vue'
import audioCall from '@/pages/calling/AudioCall.vue'
import constants from '@/exports/constants'
import ErrorModalComponent from '@/components/ErrorModalComponent.vue'
import { useRewardful } from '@/composables/useRewardful'
import { useIntervalFn, useMutationObserver } from '@vueuse/core'

const { t } = useI18n({ useScope: 'global' })

const mode = useColorMode({ attribute: 'color-scheme' })

const loadingIconJSONUrl = computed(
  () =>
    `${getCDNImageUrl.value}${mode.value == 'dark' ? 'loadingIconDark.json' : 'loadingIcon.json'}`
)
const loadingIconBackupUrl = computed(
  () =>
    `/loading/${mode.value == 'dark' ? 'loadingIconDark.json' : 'loadingIcon.json'}`
)

const trackingIdentifyWithId = inject<ITrackingIdentifyWithIdFunction>(
  '$trackingIdentifyWithId'
) as ITrackingIdentifyWithIdFunction

const router = useRouter()
const accountSettingsStore = useAccountSettingsStore()
const commonStore = useCommonStore()
const messageStore = useMessagesStore()
const calendarStore = useCalendarStore()
const infoLibraryStore = useInfoLibraryStore()
const moneyTransferStore = useMoneyTransferStore()
const vaultStore = useVaultStore()
const recordsStore = useRecordsStore()

const { handleNewThreadNotifications, handleNewReplyNotifications } =
  messageStore
const { handleCalendarNotifications } = calendarStore
const { handleInfoLibraryNotifications } = infoLibraryStore
const { handleNewPaymentRequestNotification } = moneyTransferStore
const { handleThumbnailGeneratedNotification } = vaultStore
const { requestPrintedReportInfo } = recordsStore

const {
  setObserverVal,
  loading,
  isOnline,
  isMobileWidth,
  fullUserInfo,
  fullUserInfoInit,
  isUserMatched,
  layoutOptions,
  fbAuthToken,
  getCDNImageUrl,
  errorModal
} = storeToRefs(commonStore)

const {
  fetchBadgeCounts,
  fetchActivityCounts,
  fetchAppSettings,
  setLoading,
  setLayoutCommonDataLoaded,
  setSetObserverValMethod,
  setWindowWidthChanges,
  fetchFullUserInfo,
  fetchFirebaseAuthToken,
  resetErrorModal,
  fetchAndProcessFeatureFlags
} = commonStore

const {
  fetchSubscriptionInfo,
  hasUserAcceptedLatest,
  getLatestToS,
  acceptTermsOfService
} = accountSettingsStore

const callingStore = useCallingStore()
const { getActiveVideoCallByItemID, setCoparentDecliningCall } = callingStore
const { showAudioCall, showVideoCallError, roomResult, activeCall } =
  storeToRefs(callingStore)

const { createSlot, closeModal, generateModal, HTMLtoComponent } = useModals()
// 1 minute for dev. 1 hour for production
const pollFeatureFlagsInterval =
  import.meta.env.MODE === 'development' ? 60 * 1000 : 60 * 1000 * 60

const state = reactive<{
  errorModalEl: HTMLElement | null
}>({
  errorModalEl: null
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let stopListening: any = null

watch(setObserverVal, async (val) => {
  if (val) {
    setObserver()
  }
})

const el = ref<HTMLElement | null>(null)
const pageHeight = ref()

useResizeObserver(el, (entries) => {
  const [entry] = entries
  const { height } = entry.contentRect
  pageHeight.value = height
})

onBeforeMount(() => {
  setLoading(true)
})

onMounted(async () => {
  document.title = 'Co-Parenting Communication Tools | TalkingParents'
  const _metaDesc: HTMLMetaElement | null = document.head.querySelector(
    'meta[name=description]'
  )
  if (_metaDesc) {
    _metaDesc.content =
      'TalkingParents is a communication and coordination platform offering co-parents the tools to focus on raising children.'
  }
  document.head
    .querySelector('meta[name=robots]')
    ?.setAttribute('content', 'noindex')
  //make sure user is matched before calling everything else
  if (!fullUserInfoInit.value) await fetchFullUserInfo()

  if (!fullUserInfo.value) {
    // there was an error
    router.push({ name: 'logout' })
    return
  }

  nextTick(() => {
    if (!el.value) return

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.target instanceof HTMLElement) {
          // Remove the entire style attribute
          mutation.target.removeAttribute('style')
        }
      })
    })

    observer.observe(el.value, {
      attributes: true,
      attributeFilter: ['style'] // Only watch style changes
    })
  })

  if (isUserMatched.value) {
    Promise.all([
      fetchAppSettings(),
      fetchBadgeCounts(),
      fetchActivityCounts(),
      fetchSubscriptionInfo()
    ]).then(async () => {
      setLayoutCommonDataLoaded(true)
      setObserver()
      addUserInfoToRaygun()
      authenticatFirebase()
      useIntervalFn(
        () => {
          fetchAndProcessFeatureFlags()
        },
        pollFeatureFlagsInterval,
        { immediate: true }
      )
      checkTOS()
      // eslint-disable-next-line camelcase
      trackingIdentifyWithId({
        talkingparents_id: fullUserInfo.value.userId?.toString(),
        email: fullUserInfo.value.email,
        firstName: fullUserInfo.value.firstName,
        lastName: fullUserInfo.value.lastName,
        phone: fullUserInfo.value.phoneNumber,
        matched: true,
        consent: false
      })

      window.addEventListener('resize', handleResize)
      handleResize()

      setLoading(false)
    })
  } else {
    router.push({ name: 'logout' })
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
  if (stopListening) {
    stopListening()
  }
})

async function checkTOS() {
  const accepted = await hasUserAcceptedLatest('1')
  if (!accepted) {
    showTOSConsent()
  }
}

async function showTOSConsent() {
  const tosObj = (await getLatestToS('1')) as ITosResult
  if (tosObj) {
    generateModal({
      slot: createSlot(
        'content',
        HTMLtoComponent(tosObj.termsOfServiceHtml || ''),
        {
          class: 'stack'
        }
      ),
      callback: {
        confirmFn: () => acceptConsent(tosObj)
      },
      default: {
        headerText: 'Terms of Service',
        footerButtonLabel: 'Accept'
      },
      config: {
        showFooter: true,
        showSecondaryCTA: false,
        footerStyle: 'flex-reverse gap-4 p-1',
        showCloseButton: false,
        closeOnOutsideClick: false,
        closeOnConfirm: true,
        closeOnLoadEnd: true
      }
    })
  }
}

async function acceptConsent(tosObj?: ITosResult) {
  if (tosObj) {
    const consentAccepted = await acceptTermsOfService(tosObj.itemID)
  }
}

async function authenticatFirebase() {
  //get token
  await fetchFirebaseAuthToken()
  // authenticate user
  await realtimeDatabaseService
    .authenticateUser(fbAuthToken.value)
    .then(() => initRealTimeDBNotifications())
}

const initRealTimeDBNotifications = async () => {
  // Start listening for new data
  const pathV2 = '/notifications-v2/' + fullUserInfo.value.caseId
  stopListening =
    realtimeDatabaseService.listenForNewEntries<IRealTimeNotificationV2>(
      pathV2,
      handleNewNotificationV2
    )
}

const handleNewNotificationV2 = async (
  data: IRealTimeNotificationV2 | null
) => {
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const childObj = data[key]
      const path = `/notifications-v2/${fullUserInfo.value.caseId}/${key}`
      if (!ignoreV2Notification(childObj, key)) {
        processNotifcation(childObj, key)
        if (childObj.type != 'videoCall') {
          deleteNotification(path)
        }
      }
    }
  }

  async function processNotifcation(
    childObj: IRealTimeNotificationObject,
    key: string
  ) {
    switch (childObj.type) {
      case 'videoCall':
        if (
          childObj?.roomId &&
          childObj?.senderUserId != fullUserInfo.value.userId
        ) {
          if (childObj.roomId != activeCall.value?.itemID) {
            await getActiveVideoCallByItemID(childObj.roomId)
            openIncomingCallModal(
              DateTime.fromISO(childObj.expiration, { zone: 'utc' })
            )
          }
        } else if (childObj?.senderUserId == fullUserInfo.value.userId) {
          callNotificationReceivedSender(
            DateTime.fromISO(childObj.expiration, { zone: 'utc' })
          ) // tell video call modal notification was received
        }

        subscribeIncomingCall(
          `/notifications-v2/${fullUserInfo.value.caseId}/${key}`
        ) // will delete on accept/decline/timeout
        break
      case 'infoLibraryCardShared':
      case 'infoLibraryCardUpdated':
        handleInfoLibraryNotifications(childObj?.itemId)

        break
      case 'apRequestCreated':
      case 'apRequestDeclined':
      case 'apPaymentCreated':
      case 'apPaymentCompleted':
        fetchBadgeCounts()
        handleNewPaymentRequestNotification()
        break
      case 'calendarEventCreated':
        handleCalendarNotifications(0)
        break
      case 'calendarEventEdited':
        handleCalendarNotifications(1)
        break
      case 'calendarEventDeleted':
        handleCalendarNotifications(2, childObj?.itemId)
        break
      case 'newConversation':
        if (childObj?.conversationId)
          handleNewThreadNotifications(childObj?.conversationId)
        break
      case 'message':
        if (childObj?.conversationId) {
          handleNewReplyNotifications(
            childObj?.senderUserId,
            childObj?.conversationId
          )
        }
        break
      case 'printedReportCalculationComplete':
        if (childObj?.reportJobId) {
          requestPrintedReportInfo(childObj?.reportJobId)
        }
        break
      case 'vaultThumbnailGenerated':
        if (childObj?.itemId) {
          handleThumbnailGeneratedNotification(childObj?.itemId)
        }
        break
      case 'videoCallDeclined':
        if (childObj?.videoCallId) {
          setCoparentDecliningCall(childObj?.videoCallId)
          unregisterIncomingCall()
        }
        break
      case 'messageViewed':
        //ignoring for now
        break
      default:
        console.log('Unknown type:', childObj.type, childObj)
    }
  }
}

function ignoreV2Notification(
  data: IRealTimeNotificationObject | null,
  key: string
) {
  if (data) {
    switch (data?.type) {
      case 'vaultThumbnailGenerated':
      case 'printedReportCalculationComplete':
        if (data?.senderUserId == fullUserInfo.value.userId) {
          return false
        } else {
          return true
        }
      case 'videoCall':
        if (
          DateTime.fromISO(data?.expiration, { zone: 'utc' }) <
          DateTime.local({ zone: 'utc' })
        ) {
          return true
        } else {
          return false
        }
      case 'videoCallDeclined':
        if (data?.senderUserId == fullUserInfo.value.userId) {
          return true
        } else {
          return false
        }
      default:
        if (data?.senderUserId == fullUserInfo.value.userId) {
          return true
        } else {
          return false
        }
    }
  }
}

function deleteNotification(path: string) {
  realtimeDatabaseService.deleteData(path)
}

const callNotif = useEventBus<IVideoCallNotification>('callNotification')

function subscribeIncomingCall(notificationPath: string) {
  callNotif.once((requestNotification: IVideoCallNotification) => {
    if (
      requestNotification.status == constants.callNotifStatus.accepted ||
      requestNotification.status == constants.callNotifStatus.declined ||
      requestNotification.status == constants.callNotifStatus.ignored
    ) {
      deleteNotification(notificationPath)
    }
  })
}

function unregisterIncomingCall() {
  callNotif.reset()
}

function callNotificationReceivedSender(expiresWhen: DateTime) {
  callNotif.emit({
    expiresWhen: expiresWhen,
    status: constants.callNotifStatus.received
  })
}

function openIncomingCallModal(expiresWhen: DateTime) {
  const el = generateModal({
    slot: {
      content: createSlot('content', incomingVideoCall, {
        onCancel: () => closeModal(el),
        onSuccess: () => closeModal(el),
        onDropped: () => {
          closeModal(el)
          openCallEndedModal()
        },
        expiresWhen: expiresWhen
      }).content
    },
    config: {
      showHeader: false,
      showFooter: false,
      showCloseButton: false,
      closeOnOutsideClick: false,
      callOnce: true
    }
  })
}

function openCallEndedModal() {
  const callEndedString = t('calling.videoCall.endedModal.content')
  const callEnded = `<span class="place-center">${callEndedString}</span>`

  generateModal({
    default: {
      headerText: t('calling.videoCall.endedModal.header'),
      footerButtonLabel: 'Okay'
    },
    slot: {
      content: createSlot('content', HTMLtoComponent(callEnded)).content
    },
    config: {
      showHeader: true,
      showBody: true,
      showFooter: true,
      addContentPadding: true,
      closeOnConfirm: true,
      showCloseButton: true
    }
  })
}

const debouncedFn = useDebounceFn(
  () => {
    setWindowWidthChanges()
  },
  500,
  { maxWait: 1000 }
)

async function handleResize() {
  debouncedFn()
}

function setObserver() {
  setSetObserverValMethod(false)
}

function addUserInfoToRaygun() {
  rg4js('setUser', {
    identifier: fullUserInfo.value.userId,
    fullName: fullUserInfo.value.fullName,
    email: fullUserInfo.value.email,
    isAnonymous: false,
    uuid: 'Web App'
  } as unknown as RaygunV2UserDetails)
}

watch(
  () => errorModal.value.showErrorModal,
  (val) => {
    if (!val) {
      return
    }

    const el = generateModal({
      default: {
        footerButtonLabel: 'Okay'
      },
      slot: {
        content: createSlot('content', ErrorModalComponent).content
      },
      config: {
        showHeader: false,
        showFooter: true,
        addContentPadding: false
      },
      callback: {
        confirmFn: () => {
          resetErrorModal()
          closeModal(el)
        },
        closeFn: resetErrorModal
      }
    })
  }
)
</script>
<style scoped lang="scss">
.viewport-height {
  height: 100dvh;
}
</style>
