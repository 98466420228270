<template>
  <div
    class="main-header flex mw-0"
    :class="[
      { 'main-header-shadow': layoutOptions.showShadow },
      { 'hide-mobile-header': !layoutOptions.showMobileHeader }
    ]"
  >
    <div class="main-heading mw-0">
      <div class="heading-title">
        <BackButton
          v-if="
            layoutOptions.showBackbutton ||
            showCallingBackButton ||
            showMessages3ColumnBackButton
          "
          :to="layoutOptions.backButtonRouteName"
          :label="layoutOptions.backButtonLabel"
          :use-emit="
            showAudioCall ||
            (isCalling && !isQuickAccess) ||
            showVideoCallError ||
            isMessages3Column
          "
          @go-back="goBackButton"
        ></BackButton>
        <div class="header-content">
          <avatar
            v-if="layoutOptions.showHeaderAvatar"
            :is-parent="false"
            class="on-mobile"
            :size="1"
          />
          <popper-component
            v-if="layoutOptions.showHeaderAvatar"
            class="info mw-0"
            placement="bottom"
            :interactive="true"
            :arrow="true"
          >
            <h2 class="sub-header ellipsis">
              <span v-dompurify-html="layoutOptions.headerText"></span>
              <sup v-if="layoutOptions.showServiceMark">SM</sup>
            </h2>
            <template #content>
              <div class="popper">
                <span
                  >{{ layoutOptions.headerText
                  }}<sup v-if="layoutOptions.showServiceMark">SM</sup></span
                >
              </div>
            </template>
          </popper-component>
          <h2 class="sub-header" v-if="pageHeaders.pageBreadcrumbs && isVault">
            <BreadcrumbComponent
              :breadcrumbs="pageHeaders.pageBreadcrumbs"
              :show-go-back-button="true"
              :back-button-override="!isQuickAccess && !subscriptionT0"
              :breadcrumb-view-limit="1"
              :allow-ellipse="isVaultDetailItemFolder ?? true"
              go-back-icon-string="fa-regular fa-chevron-left"
              path-divider-icon-string="fa-light fa-slash-forward"
              path-divider-icon-class="icon-size-0"
              @breadcrumb-clicked="clickCrumb"
              @go-back="goBackBreacrmub"
              @edit="edit"
            />
          </h2>
          <h2
            v-else-if="!layoutOptions.showHeaderAvatar"
            class="sub-header ellipsis"
            :class="{
              'wrap-text': featureFlags?.web_messages_enable3ColumnLayout
            }"
          >
            <span v-dompurify-html="layoutOptions.headerText"></span>
            <sup v-if="layoutOptions.showServiceMark">SM</sup>
          </h2>
          <infoButton v-if="layoutOptions.showInfoButton" />
        </div>
      </div>
    </div>
    <div id="header-actions" class="ml-auto header-actions">
      <calendar-notifications
        v-if="layoutOptions.showCalendarNotificationsButton"
        class="mobile-header"
      ></calendar-notifications>
      <!--Add New -->
      <add-new-button
        v-if="layoutOptions.showAddNewButton"
        :icon-class="getIconClass()"
        :use-emit="isMessages3Column ?? false"
        @add-new="addNew3Column"
        class="align-middle"
      ></add-new-button>
      <!--Delete for Journal -->
      <button
        v-if="
          route.name == 'journalEntry' &&
          route.params.itemId &&
          route.params.itemId != '0'
        "
        id="btnDeleteEntry"
        type="button"
        class="btn tertiary color-brand ml-auto flex gap-2"
        :disabled="callConfirmDelete"
        @click="setCallConfirmDelete(true)"
      >
        <font-awesome-icon icon="fa-light fa-trash-can" class="icon" />
      </button>
      <!--Cart button for Records main page-->
      <div
        v-if="route.name == 'recordsMain'"
        class="ml-auto flex gap-2"
        style="position: relative"
      >
        <button
          class="btn tertiary color-brand cart-icon"
          @click="setOrderSummaryActive(!orderSummaryActive)"
        >
          <font-awesome-icon
            icon="fa-light fa-cart-shopping"
            style="width: 21px; height: 21px"
          />
          <span class="sr-only">cart</span>
        </button>
        <span v-if="getSelectedRecords.length" class="cart-notification">{{
          getSelectedRecords.length
        }}</span>
      </div>

      <!-- calling -->
      <button
        v-if="
          isCalling &&
          showMobileHeaderCog &&
          !subscriptionT0 &&
          (welcomePagesViewed.calling || activityCounts.callCount > 0)
        "
        type="button"
        class="btn tertiary brand calling-settings"
        @click="goToCallingSettings"
      >
        <font-awesome-icon class="icon-size-2" icon="fa-light fa-cog" />
        <span class="sr-only">{{ t('calling.header.cogScreenReader') }}</span>
      </button>

      <div
        v-if="
          (route.name == 'viewMessage' && !showAudioCall) ||
          (route.name == 'messages' &&
            featureFlags?.web_messages_enable3ColumnLayout &&
            isDetailsView &&
            selectedMessageThread.threadID &&
            !showAudioCall)
        "
        class="ml-auto flex gap-2 align-self-end"
      >
        <!-- todo: i think there is a specific accountable callling icon for this  -->
        <button
          type="button"
          class="btn tertiary color-brand"
          @click="threadAudioCall"
        >
          <!-- <i class="fa-regular fa-phone icon-size-2" /> -->
          <font-awesome-icon class="icon-size-2" icon="fa-light fa-phone" />
          <span class="sr-only">phone</span>
        </button>
        <button
          type="button"
          class="btn tertiary color-brand"
          @click="threadVideoCall"
        >
          <!-- <i class="fa-regular fa-video icon-size-3" /> -->
          <font-awesome-icon class="icon-size-3" icon="fa-light fa-video" />
          <span class="sr-only">video</span>
        </button>
        <ellipse-button-menu
          v-if="showEllipseMenuButton"
          class="right-align-button"
          :icon-class="`brand icon-size-${isMobileWidth ? '2' : '3'}`"
        >
          <li>
            <button
              type="button"
              class="btn tertiary menu-item"
              style="width: 100%"
              @click="callRecordsEmailPrompt"
            >
              {{ t('records.partialRecords.emailRecord') }}
            </button>
          </li>
        </ellipse-button-menu>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import AddNewButton from '@/components/library/AddNewButton.vue'
import { useCommonStore } from '@/stores/CommonStore'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
import BackButton from '@/components/library/BackButton.vue'
import Avatar from '@/components/library/Avatar.vue'
import { useJournalStore } from '@/stores/JournalStore'
import popperComponent from '@/components/PopperComponent.vue'
import CalendarNotifications from '@/pages/calendar/components/CalendarNotifications.vue'
import { useRecordsStore } from '@/stores/RecordsStore'
import infoButton from '@/components/library/InfoButton.vue'
import BreadcrumbComponent from './components/BreadcrumbComponent.vue'
import type { IBreadcrumbEvent } from '@/models/interfaces'
import { useEventBus } from '@vueuse/core'
import { useLayoutStore } from '@/stores/LayoutStore'
import { useVaultStore } from '@/stores/VaultStore'
import { ColumnLayoutColumnState, VaultColumnFilter } from '@/models/enums'
import { useCallingStore } from '@/stores/CallingStore'
import { useMessagesStore } from '@/stores/MessagesStore'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import { useI18n } from 'vue-i18n'
import { useModals } from '@/composables/useModal/useModal'
import { useAttachmentsStore } from '@/stores/AttachmentsStore'
import EllipseButtonMenu from '@/components/library/EllipseButtonMenu.vue'
import EmailRecord from '@/pages/records/components/EmailRecord.vue'
import toastService from '@/services/toastService'

const { t } = useI18n({ useScope: 'global' })

const bus = useEventBus<IBreadcrumbEvent>('breadcrumbClicked')
const editBus = useEventBus<IBreadcrumbEvent>('edit')

const route = useRoute()
const state = reactive({ isNavCollapsed: false, isNavOpen: false })
const commonStore = useCommonStore()
const { setBreadcrumbs, setMobileHeaderMounted, setDoAddNew } = commonStore
const {
  layoutOptions,
  pageHeaders,
  welcomePagesViewed,
  showingNoCount,
  activityCounts,
  featureFlags,
  doAddNew
} = storeToRefs(commonStore)

const journalStore = useJournalStore()
const { setCallConfirmDelete } = journalStore
const { callConfirmDelete } = storeToRefs(journalStore)

const recordsStore = useRecordsStore()
const { getSelectedRecords, orderSummaryActive } = storeToRefs(recordsStore)
const { setOrderSummaryActive } = recordsStore

const layoutStore = useLayoutStore()
const { columns } = storeToRefs(layoutStore)
const { setVaultQuickAccessView, setVaultMobileView } = layoutStore

const vaultStore = useVaultStore()
const {
  selectedVaultColumnFilter,
  editingItem,
  selectedItemsList,
  currentFolder,
  vaultItemAttachments
} = storeToRefs(vaultStore)
const { handleSelectedItemsListUpdate, setvaultItemAttachments } = vaultStore

const callingStore = useCallingStore()
const {
  showAudioCall,
  showVideoCallError,
  showCallingSetup,
  introVideoDiagnostics,
  showMobileHeaderCog
} = storeToRefs(callingStore)
const {
  setShowVideoCallError,
  setShowAudioCall,
  setCallThreadId,
  setVideoCallThreadId,
  tryShowVideoCall,
  setSelectedCallingItem,
  setShowCallingSetup,
  setIntroVideoDiagnostics
} = callingStore

const messagesStore = useMessagesStore()
const { selectedMessageThread } = storeToRefs(messagesStore)
const { setSelectedMessageThread } = messagesStore

const accountSettingsStore = useAccountSettingsStore()
const { subscriptionT0 } = storeToRefs(accountSettingsStore)

const attachmentsStore = useAttachmentsStore()
const { files } = storeToRefs(attachmentsStore)
const { removeAllFiles } = attachmentsStore

const { generateModal, closeActiveModal, createSlot, closeModal } = useModals()

watch(route, async () => {
  state.isNavOpen = false
  document.body.classList.remove('nav-open')
})

const showCalendarNotifications = computed(() => {
  return route.name?.toString() == 'calendar'
})

const showEllipseMenuButton = computed(() => {
  // show if Feature flag is turned on and not a free user
  return (
    featureFlags.value?.web_records_enablePartialMessageRecords &&
    !subscriptionT0.value
  )
})

function getIconClass() {
  if (isMessages.value) {
    return 'fa-light fa-pen-to-square'
  } else {
    return 'fa-light fa-plus'
  }
}

function clickCrumb(crumb: IBreadcrumbEvent, i?: number) {
  bus.emit({ ...crumb, folderDepth: i })
}

function goBackBreacrmub(
  popCrumb?: IBreadcrumbEvent,
  leftCrumb?: IBreadcrumbEvent
) {
  // not in a folder, go to quick access view
  if (!isQuickAccess.value && popCrumb?.folderDepth == 0) {
    setVaultQuickAccessView()
  } else {
    // if in nested folder...
    if (
      popCrumb?.breadcrumbData.isFolder &&
      selectedVaultColumnFilter.value == VaultColumnFilter.All &&
      pageHeaders.value.pageBreadcrumbs
    ) {
      // if editing, then only cancel edit (by deselecting item and going back to list view)
      if (editingItem.value) {
        // if edited from ellipse, remove last crumb
        if (
          selectedItemsList.value?.length == 1 &&
          currentFolder.value?.itemID != selectedItemsList.value[0].itemID
        ) {
          setBreadcrumbs(pageHeaders.value.pageBreadcrumbs.slice(0, -1))
        }
        handleSelectedItemsListUpdate([])
        setVaultMobileView()
      } else {
        // navigate back
        bus.emit({
          ...leftCrumb,
          folderDepth: leftCrumb?.folderDepth ?? 0
        } as IBreadcrumbEvent)
      }
      // else if item, no navigation just remove breadcrumb
    } else if (pageHeaders.value.pageBreadcrumbs) {
      const localIndex = leftCrumb?.folderDepth ?? 0
      setBreadcrumbs(pageHeaders.value.pageBreadcrumbs.slice(0, localIndex + 1))
      handleSelectedItemsListUpdate([])
      setVaultMobileView()
    }
  }
}

function edit(crumb: IBreadcrumbEvent) {
  editBus.emit(crumb)
}

function threadAudioCall() {
  setCallThreadId(selectedMessageThread.value.threadID ?? null)
  setShowAudioCall(true)
}

function threadVideoCall() {
  setVideoCallThreadId(selectedMessageThread.value.threadID ?? null)
  tryShowVideoCall()
}

function goBackButton() {
  if (showCallingSetup.value && introVideoDiagnostics.value) {
    setIntroVideoDiagnostics(false)
  } else if (showCallingSetup.value) {
    setShowCallingSetup(false)
  } else if (showAudioCall.value) {
    setShowAudioCall(false)
  } else if (showVideoCallError.value) {
    setShowVideoCallError(false)
  } else if (isCalling.value && isListView.value) {
    setVaultQuickAccessView()
  } else if (isCalling.value && isDetailsView.value) {
    setVaultMobileView()
    setSelectedCallingItem(null)
  } else if (isMessages3Column.value && isDetailsView.value) {
    displayDiscardAttachmentConfirm(true)
  }
}

function addNew3Column() {
  if (isMessages3Column.value) {
    displayDiscardAttachmentConfirm(false)
  }
}

function goToCallingSettings() {
  setShowCallingSetup(true)
}

const clearFormBus = useEventBus('clearForm')

function displayDiscardAttachmentConfirm(goBack: boolean) {
  if (
    (files.value.length > 0 || vaultItemAttachments.value.length > 0) &&
    selectedMessageThread.value.threadID
  ) {
    generateModal({
      default: {
        headerText: 'Leave and discard attachments?',
        contentText: 'Attachments will not be saved with your draft.',
        footerButtonLabel: 'Continue'
      },
      config: {
        showSecondaryCTA: true,
        secondaryCTALabel: 'Cancel',
        footerStyle: 'flex-reverse gap-4'
      },
      callback: {
        confirmFn: () => {
          setvaultItemAttachments([])
          removeAllFiles()
          if (goBack) {
            setSelectedMessageThread({})
            setDoAddNew(false)
          } else {
            setDoAddNew(true)
          }
        },
        secondaryFn: () => {
          closeActiveModal()
        }
      }
    })
  } else {
    if (goBack) {
      setvaultItemAttachments([])
      removeAllFiles()
      setSelectedMessageThread({})
      setDoAddNew(false)
      clearFormBus.emit()
    } else {
      setDoAddNew(true)
    }
  }
}

const isVault = computed(() => {
  return route.meta.trackingName === 'vault'
})

const isCalling = computed(() => {
  return route.meta.trackingName === 'calling'
})

const isMessages = computed(() => route.meta.trackingName == 'messaging')

const isMessages3Column = computed(
  () =>
    isMessages.value &&
    (featureFlags?.value ?? false) &&
    featureFlags.value?.web_messages_enable3ColumnLayout
)

const isQuickAccess = computed(() => {
  return (
    columns.value.length == 3 &&
    columns.value[0] == ColumnLayoutColumnState.Visible &&
    columns.value[1] == ColumnLayoutColumnState.Hidden &&
    columns.value[2] == ColumnLayoutColumnState.Hidden
  )
})

const isListView = computed(() => {
  return (
    columns.value.length == 3 &&
    columns.value[0] == ColumnLayoutColumnState.Hidden &&
    columns.value[1] == ColumnLayoutColumnState.Visible &&
    columns.value[2] == ColumnLayoutColumnState.Hidden
  )
})

const isDetailsView = computed(() => {
  return (
    columns.value.length == 3 &&
    columns.value[0] == ColumnLayoutColumnState.Hidden &&
    columns.value[1] == ColumnLayoutColumnState.Hidden &&
    columns.value[2] == ColumnLayoutColumnState.Visible
  )
})

const showCallingBackButton = computed(() => {
  return (
    (isCalling.value &&
      !isQuickAccess.value &&
      (!showingNoCount.value || welcomePagesViewed.value.calling) &&
      !subscriptionT0.value) ||
    showCallingSetup.value ||
    introVideoDiagnostics.value
  )
})

const showMessages3ColumnBackButton = computed(
  () =>
    isMessages3Column.value &&
    isDetailsView.value &&
    !isQuickAccess.value &&
    (!showingNoCount.value || welcomePagesViewed.value.messages)
)

const isVaultDetailItemFolder = computed(
  () =>
    selectedItemsList.value &&
    selectedItemsList.value[0] &&
    selectedItemsList.value[0].isFolder
)

onMounted(() => setMobileHeaderMounted(true))
onUnmounted(() => setMobileHeaderMounted(false))

function callRecordsEmailPrompt() {
  const el = generateModal({
    slot: {
      content: createSlot('content', EmailRecord, {
        title: t('records.partialRecords.emailRecord'),
        threadID: selectedMessageThread.value.threadID,
        onSuccess: () => closeModal(el),
        onCancel: () => closeModal(el)
      }).content
    },
    default: {
      headerText: t('records.partialRecords.emailRecord')
    },
    config: {
      showHeader: true,
      showFooter: false,
      showCloseButton: true
    }
  })
}
</script>

<style lang="scss" scoped>
.header-actions {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
  margin-right: -0.8625rem;
}

.header-content {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  align-items: center;
  min-width: 0;
}

.app-header {
  height: 2.875rem;
  position: sticky;
  top: 0;
  z-index: 2000;
  white-space: nowrap;
  background-color: var(--white);

  @media (min-width: 768px) {
    padding: var(--size-00);
    height: 3.125rem;
    box-shadow: var(--box-shadow-1);
  }

  @media (min-width: 1024px) {
    padding: var(--size-00) var(--size-1);
    height: 4.5rem;
  }

  .logo-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--gray-2);

    padding-left: var(--size-00);
    padding-right: var(--size-3);
    padding-inline: var(--size-00) var(--size-3);

    @media (min-width: 768px) {
      width: 12.5rem;
      border-bottom: none;
      gap: 1rem;
      padding-left: 0;
      padding-right: 0;
      padding-inline: 0;
    }

    @media (min-width: 1024px) {
      width: 278px;
      transition: width 0.5s var(--ease-out-4);
    }

    @media (min-width: 768px) {
      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 50px;
        background-color: var(--gray-2);
        position: absolute;
        top: -8px;
        right: 0;
        opacity: 1;

        @media (min-width: 1024px) {
          height: 4.5rem;
        }
      }
    }
  }

  .heading-container {
    display: none;

    @media (min-width: 768px) {
      flex-basis: unset;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 var(--size-1) 0 34px;
      flex: 1;
      gap: var(--size-0);
    }

    @media (min-width: 1024px) {
      padding-left: 8px;
      padding-right: var(--size-1);
      padding-inline: 8px var(--size-1);
    }
  }

  .info-btn {
    display: inline-flex;
    @media (min-width: 768px) {
      display: none;
    }
  }
}

@media (min-width: 1024px) {
  .nav-collapse .logo-container {
    width: 56px;
    transition: width 0.5s var(--ease-in-4);
  }
}

.page-title {
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-2);

  @media (min-width: 768px) {
    margin-left: unset;
    margin-right: unset;
    margin-inline: unset;
    margin-top: 0;
  }
  @media (min-width: 1024px) {
    font-size: var(--font-size-5);
  }
}

.page-icon {
  display: none;
  flex: none;
  width: 1.5rem !important;
  height: 1.5rem;
  fill: currentColor;

  @media (min-width: 768px) {
    display: inline-flex;
  }
}

.nav-open .btn-mobile .inline-svg {
  transform: rotate(180deg);

  line {
    transition: all 0.5s var(--ease-1);
    transform-origin: center;
  }
  line:nth-child(1) {
    transform: translate3d(-20px, 25px, 0) rotate(45deg);
    stroke: red;
  }
  line:nth-child(3) {
    transform: translate3d(-20px, -25px, 0) rotate(-45deg);
  }
  line:nth-child(2) {
    opacity: 0;
  }
}

.btn-mobile .inline-svg {
  transform: rotate(0);

  line {
    transition: all 0.5s var(--ease-1);
    transform-origin: center;
  }
  line:nth-child(1) {
    transform: translate3d(0, 0, 0) rotate(0);
    stroke: green;
  }
  line:nth-child(3) {
    transform: translate3d(0, 0, 0) rotate(0);
  }
  line:nth-child(2) {
    opacity: 0;
  }
}
.btn-mobile {
  width: 2.5rem;
  height: 100%;
  border-radius: 50%;
  position: absolute;

  @media (min-width: 768px) {
    position: relative;
  }

  @media (min-width: 1024px) {
    display: none;
  }

  .inline-svg {
    transition: transform 0.5s var(--ease-1);
  }
}

/* Logo */
.logo-primary {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-inline: auto;
  flex: none;
  transition: width 0.25s var(--ease-out-5);

  @media (min-width: 768px) {
    margin-left: unset;
    margin-right: unset;
    margin-inline: unset;
  }

  @media (min-width: 1024px) {
    width: 195px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .logo-container {
    transition: width 0.5s var(--ease-in-3);
  }
  .nav-open .logo-container {
    width: 261px;
    transition: width 0.5s var(--ease-out-4);
  }
  /* Collapsed Logo line Divider */
  .logo-primary::before {
    content: '';
  }
}

@media (min-width: 1024px) {
  .logo-primary::before {
    content: '';
    right: -53px;
    height: 72px;
  }

  .nav-collapse .logo-primary::before {
    transition: opacity 0.5s var(--ease-in-4);
    opacity: 1;
    right: -2px;
    height: 72px;
  }
  .nav-collapse .logo-primary {
    margin-right: var(--size-000);
    width: 55px;
    transition: width 0.5s var(--ease-in-4);
  }
}

/* Logo Icon */
.logo-svg {
  flex: none;
  width: 96px;
  height: 24px;
  transition: width 0.25s var(--ease-5);

  @media (min-width: 768px) {
    width: 98px;
    height: 25px;
  }

  @media (min-width: 1024px) {
    width: 145px;
    height: 36px;
  }
}

/* Action Items */
.app-header .menu-inline {
  gap: var(--size-3);
  align-items: center;
  display: none;

  @media (min-width: 1024px) {
    display: flex;
  }

  .fa-messages {
    width: 1.7rem !important;
    height: 1.7rem !important;
  }
}

/* Collapse Button */
.btn-collapse {
  display: none;
  padding: 0.5rem 0.75rem;
  aspect-ratio: 1;

  @media (min-width: 1024px) {
    display: inline-flex;
    margin-right: var(--size-1);
  }
}

.page-info {
  background-color: transparent;
  border: none;
}

/* State collapsed Navigation */
@media (min-width: 1024px) {
  /* Logo Icon */
  .nav-collapse .logo-svg {
    width: auto;
    height: 31px;
  }
  .nav-collapse .tp-letters {
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s var(--ease-5) 0.25s;
  }

  .tp-letters {
    opacity: 1;
    visibility: visible;
    transition: all 0.25s var(--ease-5) 0.25s;
  }

  .fa-angles-left {
    transform: scaleX(1);
    transition: transform 0.5s var(--ease-5) 0.5s;
  }
  .nav-collapse .fa-angles-left {
    transform: scaleX(-1);
  }
}
.sub-header {
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-2);
  @media (min-width: 768px) {
    font-size: var(--font-size-4);
  }
}

.wrap-text {
  text-wrap: wrap;
}

.cart-icon {
  position: relative;
  width: 21px;
  height: 21px;
}

.cart-notification {
  position: absolute;

  width: 1rem;
  height: 1rem;
  background: var(--magenta);
  color: white;
  font-weight: var(--font-weight-1);
  border-radius: 0.5rem;

  inset-inline-start: 0.75rem;
  inset-block-start: -0.5rem;

  font-size: var(--font-size-00);
  pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: center;
}

.main-header-shadow {
  box-shadow: var(--box-shadow-1);
}

[color-scheme='dark'] {
  .main-header-shadow {
    box-shadow: var(--shadow-2);
  }
}

.hide-mobile-header {
  top: -2.8625rem !important;
}

.calling-settings {
  margin-right: -0.75rem;
}

:deep(.header-content em) {
  font-style: normal;
}
</style>
